export const CampElveng = (props) => {
  return (
    <div id='camp-elveng' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Camp Elveng</h2>
          <p>
          Eventyrskogene er en del av et større, firedelt prosjekt som vi har døpt <em>Camp Elveng</em>. Teamet bak Camp Elveng ønsker å bidra 
          til bygdeutvikling ved å skape gode opplevelser for små og store i samspill med dyr og natur, inkludering, godt vertskap, god atmosfære, og formidling av de gode historiene.
          Prosjektet tar utgangspunktet i området på og rundt Austerli Gård på Fosen.
          </p>
        </div>
        <div id='row'>
          <div className='camp-images'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 camp-elveng'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className={`camp-elveng-img ${d.selected === "True" ? "" : "transparent-image"}`} />
                    <div className='caption'>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
            </div>
        </div>
        <div className='row' className="text-left">
          <div className='col-xs-12 col-md-6'>
            <p>
              Vi har en målsetning om å bygge opp en bærekraftig bedrift basert på <em>Sosialt entreprenørskap</em>. Vi har tro på at det er mulig å dyrke frem et positivt
              samspill mellom det <em>offentlige</em>, <em>frivillighet</em> og <em>bedrift</em>, men man kommer ikke bort ifra at det trengs kommersialitet i bunn for å ha 
              nødvendig bærekraft til å gjennomføre små og store prosjekter over tid. Likevel er dette prosjektet i hovedsak basert på dugnasånd, rom til kvalitetstid & glede, 
               og involvering og formidling.
            </p>
            <p>
              Ved å skape et <em>«eget samfunn»</em> der vi kombinerer det offentlige, frivillighet, og bedrift på samme sted, vil vi gjøre en forskjell,
              både for de som har et ekstra behov for tilhørighet, samt frivillige som har overskudd til å gi litt ekstra av seg selv og vil være medmennesker.
              Camp Elveng vil også kunne bidra med sårt tiltrengte arbeidsplasser til Distrikt-Norge. Her er det stort potensiale for utvikling - vi har store arealer å boltre oss på!
            </p>
            <h3>Klubb</h3>
            <p>
              <em><b>Austerli-Bjugn Hestesportsklubb</b></em> ble stiftet i 2016, er medlem av NIF og Norges Rytterforbund, og har pr. i dag over 70 medlemmer. Klubben har nylig
              ferdigstilt en 1.9 km lang feltrittsløype. Klubben vil i hovedsak benytte løypa fra mai til september. Fra oktober til mars har Eventyrskogene
              fått tilgang til løypa gjennom en leie/bruksavtale, som sikrer klubben nødvendige inntekter til vedlikehold.
            </p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <h3>Hest & Helse</h3>
            <p>
            Det blir stadig bedre viten om hvilke helseforebyggende effekter det har for mennesker i alle aldre å tilbringe tid med våre 4-bente venner. Særlig kan det nevnes ungdommers
            opplevelse av mestring og tilhørighet gjennom vanlig stell og ride opplæring.
            </p>
            <p>
              Austerli Gård er en godkjent <em><b>Inn På Tunet</b></em> gård - blant annet tilbyr vi et lavterskel camp tilbud for barn i alderen 8-14 år på sommerhalvåret.
              Resten av året foregår det en rekke andre aktiviteter på gården.
            </p>
            <h3>Eventyrskogene</h3>
            <p>
              Vi har engasjert et stjernelag av norges fremste eksperter og talenter på blant annet hest og opplevelsesproduksjon. Dermed har vi lagt listen for prosjektet; 
              <em> kompromissløs satsing på kvalitet!</em>
            </p>
            <h3>Historie</h3>
              <p>
                Bygda Elveng - eller «<em>Nyjorda</em>» ble til som et resultat av statens <em>Ny Jord</em> prosjekter på 1930-tallet.
              </p>
              <p>
                Bureisningsprogrammet var et av flere tiltak Staten iverksatte for å demme opp for den store
                arbeidsløsheten, spesielt på landsbygda. Dersom man i løpet av 5 år klarte å dyrke opp nok
                areal til å brødfø en familie – fikk man ofte tildelt et betydelig areal gratis. Så den nyere
                historien om Elveng, er sagaen om unge kvinner og menn som slet seg ut på å gjøre myra
                dyrkbar – bokstavelig talt med håndmakt. Men skrur man tiden tilbake til 1000 årsskiftet var
                det samer og eier av skandinavias største jordeiendom «Fru Inger fra Austrått» som regjerte
                grunnen her.
              </p>
          </div>
        </div>
      </div>
    </div>
  )
}
