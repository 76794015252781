import { SRLWrapper } from "simple-react-lightbox";

export const Gallery = (props) => {
  return (
    <SRLWrapper>
    <div id='gallery' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Galleri</h2>
          <p>
          </p>
        </div>
        <div className='row'>
          <div className='gallery-items'>
          <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/elvelutter1.png'
                    title='Urlutt og Absolutt'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Urlutt og Absolutt</h4>
                    </div>
                    <img
                      src='img/galleri/elvelutter1.png'
                      className='img-responsive img-full'
                      alt='Urlutt og Absolutt'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/elvelutter2.png'
                    title='Trudelutt og Høylutt'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Trudelutt og Høylutt</h4>
                    </div>
                    <img
                      src='img/galleri/elvelutter2.png'
                      className='img-responsive img-full'
                      alt='Trudelutt og Høylutt'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/myrkeldesign.png'
                    title='Myrkel'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Myrkel</h4>
                    </div>
                    <img
                      src='img/galleri/myrkeldesign.png'
                      className='img-responsive img-full'
                      alt='Myrkel'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/hestslede.png'
                    title='Reisen gjennom Eventyrskogene skjer med hest og slede'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Reisen gjennom Eventyrskogene skjer med hest og slede</h4>
                    </div>
                    <img
                      src='img/galleri/hestslede.png'
                      className='img-responsive img-full'
                      alt='Reisen gjennom Eventyrskogene skjer med hest og slede'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/lys.png'
                    title='På vei gjennom løypa oppleves magien i Eventyrskogene'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>På vei gjennom løypa oppleves magien i Eventyrskogene</h4>
                    </div>
                    <img
                      src='img/galleri/lys.png'
                      className='img-responsive img-full'
                      alt='På vei gjennom løypa oppleves magien i Eventyrskogene'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/stemning.png'
                    title='Illustrasjon, Eilifs låve i bakgrunnen'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Illustrasjon, Eilifs låve i bakgrunnen</h4>
                    </div>
                    <img
                      src='img/galleri/stemning.png'
                      className='img-responsive img-full'
                      alt='Illustrasjon, Eilifs låve i bakgrunnen'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/etg1.png'
                    title='1 etg Eilifs Låve, plantegning'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>1 etg Eilifs Låve, plantegning</h4>
                    </div>
                    <img
                      src='img/galleri/etg1.png'
                      className='img-responsive img-full'
                      alt='1 etg Eilifs Låve, plantegning'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/etg2.png'
                    title='2 etg Eilifs Låve, plantegning'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>2 etg Eilifs Låve, plantegning</h4>
                    </div>
                    <img
                      src='img/galleri/etg2.png'
                      className='img-responsive img-full'
                      alt='2 etg Eilifs Låve, plantegning'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/etg3.png'
                    title='3 etg Eilifs Låve, plantegning'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>3 etg Eilifs Låve, plantegning</h4>
                    </div>
                    <img
                      src='img/galleri/etg3.png'
                      className='img-responsive img-full'
                      alt='3 etg Eilifs Låve, plantegning'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='gallery-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/galleri/kjeller.png'
                    title='Kjelleren i Eilifs Låve, plantegning'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Kjelleren i Eilifs Låve, plantegning</h4>
                    </div>
                    <img
                      src='img/galleri/kjeller.png'
                      className='img-responsive img-full'
                      alt='Kjelleren i Eilifs Låve, plantegning'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </SRLWrapper>
  )
}
