export const Eventyret = (props) => {
  return (
    <div id='eventyret'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <div className='section-title'>
              <h2>Eventyret</h2>
              <p className="introduction">
                Våre gjester vil forene krefter med de vennlige vesenene, «Elveluttene», for å nedkjempe
                selveste «Myrkel» - vesenet som får bygdedyret til å blekne.
          </p>
            </div>
            <p>
              Elveluttene hater utenforskap og baksnakking. De avskaffet slik styggedom i eventyrtiden.
              De laget «Elveluttloven» og under ledelse av den eldste av dem, «Urlutt», forente de seg i
              kampen mot Myrkel. Våre venner drepte ikke Myrkel, elvelutter har ikke drap i hjertene sine.
              De sperret udyret inne under en stor stein, ute i en myr. Så levde de i fred og fordragelighet
              sammen skogens dyr og fugler.
            </p>
            <p>
              Så kom menneskene til Eleveluttenes rike. Det gikk fint i begynnelsen. Menneskene hadde
              hester; hester liker Elvelutter, og Elvelutter liker hester. Særlig liker de hestemøkk som de
              trenger til <em>Fløyelsblomsttreet</em> sitt. Dette treet er sentrum i Elveluttenes univers, og gir dem
              næring for kropp og sjel. Det bærer frukter i alle regnbuens farger hele året og det gir fra seg
              den vidunderligste musikk når vinden rusker i bladene, eller sevjen drypper fra knoppene.
              Treet står i regnbuedalen på andre siden av myrene. Det er der regnbuen holder til og lyser
              hele året, natt som dag.
            </p>
            <p>
              Men menneskene tørket ut myra og fjernet steinen for å få ny åkerjord. Dermed var Myrkel
              fri igjen. Skapningen trivdes, vokste og tok bolig i menneskene, den hvisker i vinden og
              skaper ufred. Derfor må den tilbake igjen i hullet sitt ved <em>Malstrømmen</em>, der må det
              sperres inne. Det er målet med opplevelsen på Elveng.
            </p>
            <div className='col-lg-6 col-sm-6 col-xs-12'>
              <div className="quote">
                <p>
                  «Det nye opplevelseskonseptet "Eventyrskogene" er et spennende initiativ som har potensialet til å treffe både
                  nasjonal og internasjonal marked. Opplevelsen har fokus på å formidle både norsk kultur, myter, og
                  naturgleden. Det er positivt at det blomstrer frem nye opplevelsesprodukter i Trøndelag som bidrar til fylkets satsning som
                  opplevelsesdestinasjon mot 2030.»
                </p>
                <p className="quote-caption">
                  - Petra Sestak, Trøndelag Reiseliv, Reiselivssjef, Januar 2021
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-sm-6 col-xs-12'>
              <div className="quote">
                <p>
                  «Camp Elveng og Eventyrskogene handler om noe unikt og alt annet enn middelmådighet. I det ligger
                  suksessen. Du kan designe og skape, og bygge det mest fantastiske stedet i verden. Men det trengs de riktige
                  menneskene for å gjøre drømmen til virkelighet. Menneskene i dette prosjektet er det det unike og derfor verdt
                  å investere i»
                </p>
                <p className="quote-caption">
                  - Tom Myrvold, Ordfører Ørland Kommune, Desember 2020
                </p>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-md-6'>
              <img src="img/myrkel.png" className="myrkel" alt="Myrkel"></img>
          </div>
        </div>
      </div>
    </div>
  )
}
