export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6 right'>
            <div className='about-text'>
              <h2>Om Eventyrskogene</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <p>{props.data ? props.data.paragraph2 : 'loading...'}</p>
              <p>{props.data ? props.data.paragraph3 : 'loading...'}</p>
              <div className='list-style'>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/loype.jpg' className='img-responsive' alt='' />{' '}
            <div className='col-lg-6 col-sm-6 col-xs-12'>
              <img src='img/nils.png' className='quote-image' />
            </div>
              <div className='col-lg-6 col-sm-6 col-xs-12'>
                <p className='quote'>
                «Med dette unike konseptet ønsker vi å vise at det er mulig å skape 
                fantastiske opplevelser for store og små  i naturen på Fosen. 
                Vi vil løfte samspillet mellom natur, mystikk og hester til en 
                trolsk opplevelse ved hjelp av moderne teknologi.  
                Og vil vil gjøre dette gjennom et eventyr basert på lokale myter og legender.» 
                </p>
                <img src='img/signatur-sort.png' className='quote-signature'>
                </img>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}
